import request from "@/utils/request";

// mouvement de stock
export function fetchMovementStock(query) {
  return request({
    url: "/movement-stock/fetch",
    method: "GET",
    params: query,
  });
}

export function fetchMovementStockForArticle(query, articleId) {
  return request({
    url: `/movement-stock/article/${articleId}`,
    method: "GET",
    params: query,
  });
}

export function fetchTypesMovementStock() {
  return request({
    url: "/movement-stock/types",
    method: "GET",
  });
}

export function fetchMovementStatistique() {
  return request({
    url: "/movement-stock/statistique",
    method: "GET",
  });
}

export function createStock(data) {
  return request({
    url: "/stock/add",
    method: "POST",
    data
  });
}
// gestion des bon d'entree

export function fetchRaisonVouchers() {
  return request({
    url: "/stock/vouchers/raison",
    method: "GET",
  });
}

export function createVoucher(data) {
  return request({
    url: "/stock/vouchers",
    method: "post",
    data,
  });
}

export function fetchVoucher(numero, type) {
  return request({
    url: `/stock/vouchers/${type}/${numero}`,
    method: "GET",
  });
}
export function nextNumber(type) {
  return request({
    url: `/stock/vouchers/${type}/next-number`,
    method: "GET",
  });
}

export function fetchVouchers(query, type) {
  return request({
    url: `/stock/vouchers/fetch/${type}`,
    method: "GET",
    params: query,
  });
}
export function cancelVoucher(numero,type) {
  return request({
    url: `/stock/vouchers/cancel/${type}/${numero}`,
    method: "DELETE",
  });
}



// Stock
export function fetchStockOnHand(articleId) {
  return request({
    url: `/stock/fetch/article/${articleId}`,
    method: "GET",
  });
}

export function fetchTotalStockOnHand(articleId) {
  return request({
    url: `/stock/total-stockonhand/${articleId}`,
    method: "GET",
  });
}


// adjustement de stock
export function createAdjustement(data) {
  return request({
    url: `/stock/adjustments`,
    method: "POST",
    data,
  });
}
export function getStockOnHandArticleAndStock(query) {
  return request({
    url: `/stock/stockonhand`,
    method: "GET",
    params: query
  });
}
export function deleteAdjustement(id) {
  return request({
    url: `/stock/adjustments/${id}`,
    method: "DELETE",
  });
}
export function fetchAdjustementArticle(query, article) {
  return request({
    url: `/stock/adjustments/${article}`,
    method: "GET",
    params: query,
  });
}

// inventaire
export function getStatInventaire(numero) {
  return request({
    url: `/stock-inventory/stats/${numero}`,
    method: "get",
  });
}

export function createInventaire(data) {
  return request({
    url: "/stock-inventory",
    method: "post",
    data,
  });
}

export function updateInventaire(data, numero) {
  return request({
    url: `/stock-inventory/${numero}`,
    method: "put",
    data,
  });
}

export function updateItemInventory(data) {
  return request({
    url: `/stock-inventory/items`,
    method: "put",
    data,
  });
}

export function getAllInventaire(query) {
  return request({
    url: "/stock-inventory/async",
    method: "get",
    params: query,
  });
}

export function getInventaireById(ID) {
  return request({
    url: `/stock-inventory/${ID}`,
    method: "get",
  });
}

export function getInventaireByNumero(numero) {
  return request({
    url: `/stock-inventory/code/${numero}`,
    method: "get",
  });
}

export function getItemsInventaire(query, id) {
  return request({
    url: `/stock-inventory/items/async/${id}`,
    method: "get",
    params: query,
  });
}

export function addItemsToInventaire(data) {
  return request({
    url: `/stock-inventory/items`,
    method: "post",
    data,
  });
}

export function removeItemsToInventaire(id) {
  return request({
    url: `/stock-inventory/items/${id}`,
    method: "DELETE",
  });
}

export function confirmedInventory(numero) {
  return request({
    url: `/stock-inventory/validate/${numero}`,
    method: "put",
  });
}

export function canceledInventory(numero) {
  return request({
    url: `/stock-inventory/canceled/${numero}`,
    method: "put",
  });
}

// entrepot
export function fetchWarehouse() {
  return request({
    url: `/warehouses/fetch`,
    method: "get",
  });
}

export function createWarehouse(data) {
  return request({
    url: `/warehouses`,
    method: "post",
    data
  });
}

export function updateWarehouse(data, id) {
  return request({
    url: `/warehouses/${id}`,
    method: "put",
    data
  });
}

export function activePrimaryWarehouse(id) {
  return request({
    url: `/warehouses/active-primary/${id}`,
    method: "put",
  });
}

// transfer de stock

export function fetchTransferStock(query) {
  return request({
    url: `/stock/transfers`,
    method: "get",
    params:query
  });
}

export function createTransferStock(data) {
  return request({
    url: `/stock/transfers/create`,
    method: "post",
    data
  });
}

export function getTransferStock(id) {
  return request({
    url: `/stock/transfers/number/${id}`,
    method: "get",
   
  });
}

export function cancelTransferStock(id) {
  return request({
    url: `/stock/transfers/cancel/${id}`,
    method: "put",
   
  });
}


// transfer de stock

export function fetchAdjustmentStock(query) {
  return request({
    url: `/stock/adjustments`,
    method: "get",
    params:query
  });
}

export function fetchAdjustmentArticleStock(query,articleId) {
  return request({
    url: `/stock/adjustments/${articleId}`,
    method: "get",
    params:query
  });
}
export function createAdjustmentStock(data) {
  return request({
    url: `/stock/adjustments`,
    method: "post",
    data
  });
}

export function getAdjustmentStock(code) {
  return request({
    url: `/stock/adjustments/${code}`,
    method: "get",
   
  });
}

export function cancelAdjustmentStock(id) {
  return request({
    url: `/stock/adjustments/${id}`,
    method: "put",
   
  });
}