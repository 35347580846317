<template>
  <div class="mx-6">
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <h2
            class="text-xl uppercase font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
          >
            Bon d'entrée N° {{ bon.number }}
          </h2>
        </div>
        <div>
          <el-button>Imprimer</el-button>
          <el-button
            v-if="bon.cancel === false"
            type="danger"
            :loading="loading"
            @click="handleCancel"
            >Annulé</el-button
          >
        </div>
      </div>
    </div>
    <div
      class="flex flex-col mx-auto bg-white p-5 shadow"
      v-loading="listLoading"
    >
      <div>
        <div class="mt-4">
          <div class="flex flex-row justify-between items-start mb-4">
            <div class="flex flex-col">
              <span class="mt-2 text-base">Date: {{ bon.crated_at }}</span>
              <span class="mt-1">Note: </span>
              <p class="mt-2 text-base text-gray-500 w-1/2">
                {{ bon.describe }}
              </p>
            </div>
            <div class="flex flex-col float-right justify-end text-2xl mb-3">
              <span class="font-bold"
                >Montant : {{ bon.total | moneyFilter }} XOF</span
              >
              <span class="mt-2 text-base text-gray-500"
                >Article: {{ countArticle }}</span
              >
              <div
                class="mt-2 text-base text-gray-500 flex justify-start items-center"
              >
                <span class="mr-3">Statut:</span>
                <Status :name="bon.status" />
              </div>
            </div>
          </div>

          <el-table :data="bon.items" style="width: 100%">
            <el-table-column prop="article_name" label="#" width="60">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="article_name"
              label="DÉSIGNATION"
              min-width="160"
            >
              <template slot-scope="{ row }">
                {{ row.article_name }}
              </template>
            </el-table-column>
            <el-table-column prop="quantity" label="QUANTITE" align="right">
              <template slot-scope="{ row }">
                {{ row.quantity }}
              </template>
            </el-table-column>
            <el-table-column prop="quantity" label="P.UNITAIRE" align="right">
              <template slot-scope="{ row }">
                {{ row.price | moneyFilter }}
              </template>
            </el-table-column>
            <el-table-column prop="quantity" label="TOTAL" align="right">
              <template slot-scope="{ row }">
                {{ (row.price * row.quantity) | moneyFilter }} {{user.currency_code}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchVoucher, cancelVoucher } from "@/api/stock";
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";
import Status from "@/components/Status";
import {mapGetters} from "vuex";
export default {
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { Status },
  data() {
    return {
      type: "ENTRY_VOUCHER",
      loading: false,
      listLoading: false,
      bon: {
        type: "",
        number: "",
        date: "",
        total: 0,
        status: "",
        describe: "",
        items: [],
      },
    };
  },
  computed: {
      ...mapGetters(["user"]),
    countArticle: function () {
      return this.bon.items.length;
    },
  },
  mounted() {
    this.getBons();
  },

  methods: {
    async getBons() {
      this.listLoading = true;
      const numero = this.$route.params && this.$route.params.id;
      await fetchVoucher(numero, this.type).then((res) => {
        setTimeout(() => {
          /* this.bon.type = res.data.type;
          this.bon.numero = res.data.numero;
          this.bon.describe = res.data.describe;
          this.bon.status = res.data.cancel;
          this.bon.items = res.data.items; */
          this.bon = Object.assign({}, res.data);
          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },

    async handleCancel() {
      this.loading = true;
      const numero = this.$route.params && this.$route.params.id;
      await cancelVoucher(numero, this.type)
        .then(() => {
          setTimeout(() => {
            this.$message({
              type: "success",
              duration: 5000,
              message: "Bon d'entrée a été annulé !",
            });
            this.bon.cancel = true;
            this.loading = false;
          }, 1.5 * 1000);
        })
        .catch((err) => {
          let error = err.data;
          setTimeout(() => {
            if (error.status === 409) {
              this.$message({
                type: "danger",
                duration: 5000,
                message: error.message,
              });
            }
          }, 1.5 * 1000);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
